import React from 'react'
import uuid from 'react-uuid'

/*******Icons*******/
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from "@mui/icons-material/Delete";

import { 
    Grid,Tooltip,Button,Switch,
    FormControlLabel,ButtonGroup
} 
from "@mui/material"

export const ButtonGroupSection = ( { form, setForm, question, sectionIndex, questionIndex }) => {

    const handleAddQuestion = () => {
        
        var questions = form.sections[sectionIndex].questions;
        
        questions.push({
            id: uuid(),
            title:"Pregunta Sin Título",
            type: 1,
            options: [{text: "Opción",value:""}]
        });

        setForm({
            ...form,
            questions
        })
    }

    const handleRemoveQuestion = () => {
        
        var questions = form.sections[sectionIndex].questions;
        questions.splice(questionIndex, 1);

        setForm({
            ...form,
            questions
        });
    }

    return (
        <>
            <Grid item xs={4} sx={{ mb:5 }}>
                <ButtonGroup variant="text" aria-label="text button group">
                    <Tooltip title="Agregar Pregunta">
                        <Button onClick={ handleAddQuestion }>
                            <AddIcon></AddIcon>
                        </Button>
                    </Tooltip>   
                    <Button>
                        <FormControlLabel control={<Switch defaultChecked />} label="Obligatorio" />
                    </Button>
                    <Tooltip title="Eliminar Pregunta">
                        <Button onClick={ handleRemoveQuestion }>
                            <DeleteIcon></DeleteIcon>
                        </Button>
                    </Tooltip>
                </ButtonGroup>
            </Grid>
        </>
    )
}

import { useState, useEffect, Suspense  } from "react";
import { useParams } from 'react-router-dom'

import { Container, Paper } from "@mui/material"

import { Question } from "../questions/Question";
import { InputBase } from "../forms/InputBase";

import FormService from "../../services/FormService";

export const Forms = () => { 

    let { id } = useParams()
    const [ form, setForm ] = useState({})

    useEffect(() => {
        if(id){
            getForm(id)
        }
    }, []);

    const getForm = (id) => {
        FormService.getForm(id).then((res) => {
            setForm({
                ...form,
                ...res.data
            })
        });
    }

    return (
        <Container id="container" maxWidth="md"  sx={ { mt:20 } }>      
            
            <InputBase form={form}></InputBase>
            <Suspense>
            {  form.length > 0 &&
                form.map( (sectionItem, sectionIndex) => {
                    return (
                        sectionItem.subsecciones.map( (question, questionIndex) => {
                            return (
                               <Question 
                                    form={ form } 
                                    setForm={setForm} 
                                    question={ question } 
                                    sectionIndex={ sectionIndex }
                                    questionIndex={ questionIndex }> 
                                </Question> 
                            )
                        })
                    )
                })
            }
            </Suspense>
        </Container>
    )
}
import React from 'react'

import { Grid, Paper, InputLabel, FormControl, TextField, Select, MenuItem} from "@mui/material"

export const InputBase = ( { form } ) => {

    return (
        <>
            <Paper sx={ { p:5, mb:5 }}>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <TextField label="Título del Formulario" value={ form.title } variant="outlined" />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sx={ { mt:2 } }>
                    <FormControl fullWidth>
                        <TextField 
                            label="Descripción" 
                            multiline
                            rows={4} 
                            value={ form.description } 
                            variant="outlined" 
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sx={ { mt:2 } }>
                    <FormControl fullWidth>
                        <InputLabel id="type-form-label">Tipo de Formulario</InputLabel>
                        <Select
                            labelId="type-form-label"
                            id="demo-simple-select"
                            label="Tipo de Formulario"
                            defaultValue={ form.type }
                            /*onChange={ (e) => handleChange(e,question.id) }*/
                        >
                            <MenuItem value={1}>
                                Encuesta
                            </MenuItem>
                            <MenuItem value={2}>
                                Evaluación
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Paper>
        </>
    )
}
    
import { TableCell, TableRow } from '@mui/material'
import { QuestionOptions } from './QuestionOptions'

export const QuestionClient = ({ question, options, personal, periodo, indexSection, indexSubsection, data, setData, indexQuestion }) => {
  return (
    <>  
        <TableRow>
            <TableCell sx={ {width: 1000} }>{ question.preguntas }</TableCell>
            <QuestionOptions 
              sx={ {width: 200} } 
              options={options} 
              question={question} 
              personal={personal}
              periodo={periodo}
              indexSection={indexSection}  
              indexSubsection={indexSubsection}  
              data={data} 
              setData={setData}
              indexQuestion={indexQuestion}/>
        </TableRow>
    </>
  )
}

import axios from 'axios';
import { BASE_URL_API } from  "../common/config";

class FormService {
    
    prepareFormData = (data) => {
        var form = new FormData()

        for ( var key in data ) {
            form.append(key, data[key]);
        }

        return form
    }

    async getForms(user,role) {
        return await axios.get(`${BASE_URL_API}/formulario/list/${user}/${role}`)
    }

    async getForm(id){
        return await axios.get(`${BASE_URL_API}/formulario/editform/${id}`)
    }
    
    async getFormAnswers(id,user,role){
        return await axios.get(`${BASE_URL_API}/formulario/respuesta/${id}/${user}/${role}`)
    }

    async getFormAnswersByPersonal(formulario,personal,periodo){
        return await axios.get(`${BASE_URL_API}/formulario/respuestadetalle/${formulario}/${personal}/${periodo}`)
    }

    async getFormAnswersSummary(formulario,personal,periodo){
        return await axios.get(`${BASE_URL_API}/formulario/resultadoeva/${formulario}/${personal}/${periodo}`)
    }

    async addForm(data){
        var formData = this.prepareFormData(data)
        return await axios.post(`${BASE_URL_API}/formulario/store`, formData);
    }
    
    async duplicateFormAnswer(personal,periodo){
        return await axios.post(`${BASE_URL_API}/formulario/duplicado/${personal}/${periodo}`);
    }

    async updateForm(data, formId){
        var formData = this.prepareFormData(data)
        return await axios.put(BASE_URL_API + '/' + formId, formData);
    }

    async deleteForm(formId){
        return await axios.delete(BASE_URL_API + '/' + formId);
    }

    async addAnswer(data){
        var formData = this.prepareFormData(data)
        return await axios.post(`${BASE_URL_API}/formulario/storerespuesta`, formData);
    }
    
    async getFormPeriodo(formulario, periodo){
        return await axios.post(`${BASE_URL_API}/formulario/getInfoFomularioPeriodo/${formulario}/${periodo}`);
    }

    async getFormPeriodos(){
        return await axios.post(`${BASE_URL_API}/formulario/periodos`);
    }
    
}

export default new FormService()
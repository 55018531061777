import axios from 'axios';
import { BASE_URL_API } from "../common/config";

class AuthService {

    prepareFormData = (data) => {
        var form = new FormData()

        for ( var key in data ) {
            form.append(key, data[key]);
        }

        return form
    }
    
    async login(data) {
        var formData = this.prepareFormData(data)
        return await axios.post(`${BASE_URL_API}/auth/login`, formData)
    }
}

export default new AuthService()
import React from 'react'

import {  MenuItem,Select,InputLabel, FormControl } from "@mui/material"

/*******Icons*******/
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

export const InputTypeQuestion = ( { initValue, form, setForm, question, sectionIndex, questionIndex } ) => {

    const handleChangeTypeQuestion = ( value ) => {
        
        var questions = form.sections[sectionIndex].questions;
        questions[questionIndex].type = value;

        //Short Answer?
        if(value == 1){
            questions[questionIndex].options = []
        }else{
            //Are there options?
            if(questions[questionIndex].options.length == 0){
                questions[questionIndex].options = [{
                    text:"Opción 1",
                    value:""
                }]
            }
        }

        setForm({
            ...form,
            questions
        });
    }

    return (
        <>
            <FormControl fullWidth>
                <InputLabel id="type-question-label">Tipo de Pregunta</InputLabel>
                
                <Select
                    labelId="type-question-label"
                    id="demo-simple-select"
                    label="Tipo de Pregunta"
                    defaultValue={initValue}
                    onChange={ (e) => handleChangeTypeQuestion( e.target.value ) }
                >
                    <MenuItem value={1}>
                        <FormatAlignLeftIcon></FormatAlignLeftIcon>
                        Respuesta Corta
                    </MenuItem>
                    <MenuItem value={2}>
                        <RadioButtonCheckedIcon></RadioButtonCheckedIcon>
                        Varias Opciones
                    </MenuItem>
                    <MenuItem value={3}>
                        <CheckBoxIcon></CheckBoxIcon>
                        Casillas
                    </MenuItem>
                    <MenuItem value={4}>
                        <ArrowDropDownCircleIcon></ArrowDropDownCircleIcon>
                        Desplegable
                    </MenuItem>
                    <MenuItem value={5}>
                        <ArrowDropDownCircleIcon></ArrowDropDownCircleIcon>
                        Matríz de cuadricula
                    </MenuItem>
                </Select>
            </FormControl>
        </>
    )
}

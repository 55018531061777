import React, { useEffect, Fragment } from 'react'

import { Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material'

import { QuestionClient } from '../questions/QuestionClient'

export const Subsection = ({ subsection, personal, periodo, indexSection, indexSubsection, data, setData }) => {
  
  return (
    <Fragment>
      <Typography sx={{ fontSize: 16, mt:5}} variant="h6" >
          { subsection.Subseccion }
      </Typography>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
          <TableHead>
              <TableRow>
                  <TableCell></TableCell>
                  {
                    subsection.respuestas_opciones.length > 0 &&
                      subsection.respuestas_opciones.map( (option, index) => {
                        return (<TableCell sx={{ width:250}}>{ option.titulo }</TableCell>)
                      })
                  }
                  {
                    subsection.tipo_Subseccion == 1 &&
                      <TableCell>EVIDENCIA CONDUCTUAL</TableCell>
                  }
                  {
                    subsection.tipo_Subseccion == 2 &&
                      <TableCell>
                        <TextField/>
                      </TableCell>
                  }
              </TableRow>
          </TableHead>
          <TableBody>
              {
                subsection.Preguntas.length > 0 &&
                  subsection.Preguntas.map( (question, indexQuestion) => {
                    return (
                    <QuestionClient 
                      question={question} 
                      options={subsection.respuestas_opciones} 
                      personal={personal}  
                      periodo={periodo}
                      indexSection={indexSection}  
                      indexSubsection={indexSubsection}  
                      data={data} 
                      setData={setData}
                      indexQuestion={indexQuestion}/>)
                  })
              }
          </TableBody> 
      </Table>
    </Fragment>
  )
}

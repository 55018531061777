import { Fragment, React, useContext, useEffect, useState } from 'react'
import { useParams, Link, useNavigate } from 'react-router-dom'

import { Box, Button, Card, CardContent, CircularProgress, 
    Container, FormControl, Grid, InputLabel, MenuItem, Select, 
    TextField, Typography } from '@mui/material';

import { BottomNavigation, BottomNavigationAction, Modal } from '@mui/material';

import { Subsection } from '../sections/Subsection';

import FormService from "../../services/FormService";
import EmployeeService from "../../services/EmployeeService";

import { UserContext } from '../../contexts/UserContext';

import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import DynamicFormIcon from '@mui/icons-material/DynamicForm';

import { setFormatText } from "../../common/utils"

export const FormClient = () => {

    let { id, nivel, cedula, personal: idPersonal, periodo } = useParams();
    const userContext = useContext(UserContext);
    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [responder, setResponder] = useState(false);
    
    const [ titleEditForm, setTitleEditForm] = useState("");

    const [ idPeriodo, setIdPeriodo] = useState('');
    const [ titlePersonal, setTitlePersonal] = useState('Nombre y Apellido');
    const [ periodos, setPeriodos] = useState([]);

    const [personal, setPersonal] = useState({
        Apellido: "",
        Cargo: "",
        Cedula: "",
        Empresa: "",
        Gerencia: "",
        Nivel_Contribuyente: "",
        Nombre: "",
        ASupervisor: "",
        NSupervisor: "",
        Supervisor: "",
        idpersonal: 0,
        Fecha_ingreso: ""
    })

    useEffect(() => {
        
        if(idPersonal && employees.length > 0){
            setPersonalItem(idPersonal)
        }
    }, [idPersonal, employees])
    

    useEffect(() => {
        
        getFormPeriodos();

        if(idPersonal){
            getFormAnswersByPersonal(id, idPersonal,periodo)
            getEmployee(idPersonal)
        }else{
            getForm(id)
        }

    }, []);

    useEffect(() => {
        getFormularioInfo(id, periodo);
    }, [periodo])
    

    useEffect(() => {
        if(idPeriodo !== ""){
            setTitlePersonal("Cargando datos personal...");
            getEmployees(nivel, cedula, idPersonal, idPeriodo);
        }
    }, [idPeriodo])
    
  
    const getForm = (id) => {
        FormService.getForm(id).then((res) => {
            setData(res.data)
        });
    }

    const getFormularioInfo = (formulario, periodo) => {
        FormService.getFormPeriodo(formulario, periodo).then( ( respuesta ) => {
            let titleEdit = `${respuesta.data.formulario.Titulo} - ${ respuesta.data.periodo.descripcion }`;
            setTitleEditForm(titleEdit);
        });
    }

    const getFormAnswersByPersonal = (form, personal, periodo) => {
        FormService.getFormAnswersByPersonal(form,personal,periodo).then((res) => {
            setData(res.data)
        }).catch( (error) => { 
            //setShowError(true)
        });
    }

    const getEmployees = (nivel, cedula, idPersonal, idPeriodo) => {
        EmployeeService.getEmployees(nivel, cedula, idPersonal, idPeriodo).then((res) => {
            setEmployees(res.data)
            setTitlePersonal("Nombre y Apellido");
        });
    }

    const getEmployee = (idPersonal) => {
        EmployeeService.getEmployee(idPersonal).then((res) => {
            setPersonal(res.data)
            setResponder(true)
        }).catch( (error) => { 
            //setShowError(true)
        });
    }

    const getFormPeriodos = () => {
        FormService.getFormPeriodos().then( (response) => {
            setPeriodos(response.data);
        }); 
    }

    const handleChangeEmployee = (event) => {
        
        var personalId = event.target.value;
        var item = employees.filter( employee => employee.idpersonal == personalId )
        
        setPersonal(item[0])

        if(window.confirm("Desea evaluar ahora ?")){
            FormService.duplicateFormAnswer(personalId, idPeriodo).then( (response) => {
                window.location.href = `#/forms/response/${id}/${nivel}/${cedula}/${personalId}/${idPeriodo}`;
                window.location.reload();
            }).catch( (error) => {
                //Si encuentra un error con codio 500
                if(/code 500/.test(error)){
                    //Habilitar formulario para responder nuevo
                    setResponder(true);
                }
            });
        }else{
            setResponder(false);
        }
    }

    const setPersonalItem = (idPersonal) => {
        var item = employees.filter( employee => employee.idpersonal == idPersonal )
        setPersonal(item)
    }

    const handleClickClose = () => {
        navigate(`/forms/responses/${id}`)
    }

    const handleChangePeriodo = (event) => {
        setIdPeriodo(event.target.value)
    };
    
    return (
        <Fragment>
        <BottomNavigation sx={ { mt:9, width:"md" }} showLabels >
            <BottomNavigationAction onClick={ (e) => navigate("/") } label="Formularios" icon={<DynamicFormIcon />}/>
            <BottomNavigationAction  onClick={ (e) => navigate(`/forms/responses/${id}`) }  label="Respuestas" icon={<QuestionAnswerIcon />} />
        </BottomNavigation> 
        <Container maxWidth="lg"  sx={ { mt:5, mb:12 } }>    
            <Button id="btnFinalizar" onClick={ handleClickClose } variant='contained' sx={ { mb:2 } } >Finalizar</Button>
        </Container>
        <Container id="container" maxWidth="lg" >    
            <Typography variant="h4" className='text-center' color="text.secondary" gutterBottom>
                { titleEditForm }
            </Typography>
            <Card sx={{  mb:5 }}>
                <CardContent>
                    <Typography variant="h6" color="text.secondary" gutterBottom>
                        Datos Personales
                    </Typography>
                    { idPersonal &&
                        <Fragment>
                            <Typography  color="text.secondary" gutterBottom>
                                Nombre y Apellido: <b>{ `${personal.Nombre} ${personal.Apellido}` }</b>
                            </Typography>
                            <Typography  color="text.secondary" gutterBottom>
                                Cédula: <b>{ setFormatText(personal.Cedula, "cedula") }</b>
                            </Typography>
                            <Typography  color="text.secondary" gutterBottom>
                                Gerencia: <b>{ personal.Gerencia }</b>
                            </Typography>
                            <Typography  color="text.secondary" gutterBottom>
                                Cargo: <b>{ personal.Cargo }</b>
                            </Typography>
                            <Typography  color="text.secondary" gutterBottom>
                                Fecha de ingreso: <b>{ setFormatText(personal.Fecha_ingreso, "fecha") }</b>
                            </Typography>
                        </Fragment>
                    }
                    { !idPersonal &&
                        <Fragment>
                            <FormControl sx={{ m: 1, width: '40ch' }}>
                                <InputLabel>Período</InputLabel>
                                <Select name="Periodo" value={idPeriodo} onChange={ handleChangePeriodo } label="Período"  >  
                                    {
                                        periodos.map( periodo => {
                                            if(periodo.status == 1){
                                                return <MenuItem value={periodo.id}>{ periodo.descripcion }</MenuItem>
                                            }
                                        })
                                    }
                                </Select>
                            </FormControl>
                            <FormControl sx={{ m: 1, width: '40ch' }}>
                                <InputLabel>{ titlePersonal }</InputLabel>
                                <Select id="personal" label="Nombre y Apellido" onChange={ handleChangeEmployee }>   
                                {
                                    employees.map( employee => {
                                        return <MenuItem value={employee.idpersonal}>{ `${employee.Nombre} ${employee.Apellido}` } </MenuItem>
                                    })
                                }
                                </Select>
                            </FormControl>
                            <FormControl sx={{ m: 1, width: '40ch' }} variant="standard">
                                <TextField label="Cédula" value={ setFormatText(personal.Cedula,"cedula") } variant="outlined" />
                            </FormControl>
                            <FormControl sx={{ m: 1, width: '40ch' }} variant="standard">
                                <TextField label="Departamento" value={ personal.Gerencia } variant="outlined" />
                            </FormControl>
                            
                            <FormControl sx={{ m: 1, width: '40ch' }} variant="standard">
                                <TextField label="Nombre del Supervisor" value={ `${personal.NSupervisor} ${personal.ASupervisor}` } variant="outlined" />
                            </FormControl>
                            <FormControl sx={{ m: 1, width: '40ch' }} variant="standard">
                                <TextField label="Cargo Actual" value={ personal.Cargo } variant="outlined" />
                            </FormControl>
                            <FormControl sx={{ m: 1, width: '40ch' }} variant="standard">
                                <TextField label="Nivel de Contribución del Cargo" value={ personal.Nivel_Contribuyente } variant="outlined" />
                            </FormControl>
                            <FormControl sx={{ m: 1, width: '40ch' }} variant="standard">
                                <TextField label="Fecha de Ingreso" value={ setFormatText(personal.Fecha_ingreso,"fecha") } variant="outlined"/>
                            </FormControl>
                        </Fragment>    
                    }
                </CardContent>
            </Card>
           {  data.length == 0 &&
              <CircularProgress/>
           } 
                <Card sx={{ mt:5 }}>
                    <CardContent>
                        <Typography variant="h6" color="text.secondary" gutterBottom>
                            Leyenda
                        </Typography>
                    </CardContent>
                </Card>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Card sx={{ mt:5 }}>
                            <CardContent>
                                <Typography sx={{ fontSize:14, height:"90px" }} color="text.secondary" gutterBottom>
                                    <b>PCE - Parcialmente Cumple las Expectativas:</b><br/>Logra algunas expectativas pero puede no ser de manera consistente..
                                </Typography>
                            </CardContent>
                        </Card>        
                    </Grid>
                    <Grid item xs={4}>
                        <Card sx={{  mt:5 }}>
                            <CardContent>
                                <Typography sx={{ fontSize:14, height:"90px" }} color="text.secondary" gutterBottom>
                                    <b>CE - Cumple las Expectativas:</b><br/> Logra las expectativas y ocasionalmente excede en el logro de los resultados, y demuestra comportamientos asociados al como lo hace.
                                </Typography>
                            </CardContent>
                        </Card>      
                    </Grid>
                    <Grid item xs={4}>
                        <Card sx={{  mt:5 }}>
                            <CardContent>
                                <Typography sx={{ fontSize:14, height:"90px" }} color="text.secondary" gutterBottom>
                                    <b>SE - Supera las Expectativas:</b><br/>De manera significativa y consistentemente excede las expectativas tanto en el logro de los resultados, como en el modelaje de comportamientos asociados al como lo hace.
                                </Typography>
                            </CardContent>
                            
                        </Card> 
                    </Grid>
                </Grid>

           {   data.length > 0 && responder && 
               data.map( (item, index) => {
                return (<Card key={item} sx={{ minWidth: 275, mt:2 }}>
                        <CardContent>
                            <Typography variant="h6" color="text.secondary" gutterBottom>
                                { item.titulo }
                            </Typography>
                            {
                                typeof(item.subsecciones) == "object" &&
                                    item.subsecciones.map( (subsection, indexSubsection) => {
                                        return (<Subsection 
                                                    subsection={subsection} 
                                                    personal={personal}  
                                                    periodo={ (idPeriodo)? idPeriodo : periodo }
                                                    indexSection={index} 
                                                    indexSubsection={indexSubsection}  
                                                    data={data} 
                                                    setData={setData}/>)
                                    })
                            }
                        </CardContent>
                    </Card>
                )
               })
           }
        </Container>
        </Fragment>
    )
}

import { React, useState, useEffect, Fragment } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import logo from '../../icon.png';

import { BottomNavigation, BottomNavigationAction, Button, Card, CardContent, CircularProgress, circularProgressClasses, Container, Grid, Paper, Table, TableBody, TableCell, 
    TableContainer, TableFooter, TableHead, TableRow, TextField, Typography } from '@mui/material'
import Snackbar from '@mui/material/Snackbar';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import PrintIcon from '@mui/icons-material/Print';

import FormService from "../../services/FormService";
import EmployeeService from "../../services/EmployeeService";

import { setFormatText } from "../../common/utils"

ChartJS.register(ArcElement, Tooltip, Legend);

export const FormResponseDetails = () => {
    
    var total = 0
    let { id:idForm, personal: idPersonal, periodo } = useParams()
    const navigate = useNavigate()

    const [data, setData] = useState([]);
    const [summary, setSummary] = useState([]);
    const [showError, setShowError] = useState(false);

    const [ titleEditForm, setTitleEditForm] = useState("");

    const [personal, setPersonal] = useState({
        Apellido: "",
        Cargo: "",
        Cedula: "",
        Empresa: "",
        Gerencia: "",
        Nivel_Contribuyente: "",
        Nombre: "",
        Supervisor: "",
        idpersonal: 0,
        Fecha_ingreso: ""
    })

    useEffect(() => {
        getFormData(idForm, idPersonal)
        getFormAnswersSummary(idForm, idPersonal, periodo)
        getEmployee(idPersonal)
        getFormularioInfo(idForm,periodo)
    }, []) 

    const getFormularioInfo = (formulario, periodo) => {
        FormService.getFormPeriodo(formulario, periodo).then( ( respuesta ) => {
            let titleEdit = `${respuesta.data.formulario.Titulo} - ${ respuesta.data.periodo.descripcion }`;
            setTitleEditForm(titleEdit);
        });
    }
    
    const getFormData = () => {
        FormService.getFormAnswersByPersonal(idForm,idPersonal,periodo).then((res) => {
            setData(res.data)
        }).catch( (error) => { 
            setShowError(true)
        });
    }

    const getFormAnswersSummary = (idForm, idPersonal, periodo) => {
        FormService.getFormAnswersSummary(idForm,idPersonal,periodo).then((res) => {
            setSummary(res.data)
        }).catch( (error) => { 
            setShowError(true)
        });
    }
    
    const getEmployee = (idPersonal) => {
        EmployeeService.getEmployee(idPersonal).then((res) => {
            setPersonal(res.data)
        }).catch( (error) => { 
            setShowError(true)
        });
    }

    return (
        <Fragment>
            <Snackbar
                open={showError}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                key="topcenter"
                message="Error de conexión a internet"
            > 
            </Snackbar>
            <div className='header-print-logo'>
                <img src={logo} alt="logo" height="40" width="40"/>
            </div>
            <div className='header-print'>
                <span>
                    Resultados de Evaluación - { personal.Nivel_Contribuyente }
                </span>
            </div>
            <BottomNavigation sx={ { mt:9, width:"md" }} showLabels >
                <BottomNavigationAction onClick={ (e) => navigate("/") } label="Formularios" icon={<DynamicFormIcon />}/>
                <BottomNavigationAction  onClick={ (e) => navigate(`/forms/responses/${idForm}`) }  label="Respuestas" icon={<QuestionAnswerIcon />} />
            </BottomNavigation>
            <Container id="container" maxWidth="lg"  sx={ { mt:5 } }>
                <Button className='no-print' onClick={window.print} variant="contained" color="success" sx={{ mt:2, mb:5 }}>
                    <PrintIcon sx={ { mr:1} }/> IMPRIMIR
                </Button>
                <div id="div-respuestas-print">
                <Typography variant="h4" className='text-center' color="text.secondary" gutterBottom>
                    { titleEditForm }
                </Typography>
                { personal.Nombre !== "" &&
                    <Card sx={{  mb:5 }}>
                        <CardContent>
                            <Typography variant="h6" color="text.secondary" gutterBottom>
                                Datos Personales
                            </Typography>
                            {  idPersonal  &&
                                <Fragment>
                                    <Typography  color="text.secondary" gutterBottom>
                                        Nombre y Apellido: <b>{ `${personal.Nombre} ${personal.Apellido}` }</b>
                                    </Typography>
                                    <Typography  color="text.secondary" gutterBottom>
                                        Cédula: <b>{ setFormatText(personal.Cedula, "cedula") }</b>
                                    </Typography>
                                    <Typography  color="text.secondary" gutterBottom>
                                        Gerencia: <b>{ personal.Gerencia }</b>
                                    </Typography>
                                    <Typography  color="text.secondary" gutterBottom>
                                        Cargo: <b>{ personal.Cargo }</b>
                                    </Typography>
                                    <Typography  color="text.secondary" gutterBottom>
                                        Fecha de ingreso: <b>{ setFormatText(personal.Fecha_ingreso,"fecha") }</b>
                                    </Typography>
                                </Fragment>
                            }
                        </CardContent>
                    </Card>      
                }  
                <Card sx={{ mt:5 }}>
                    <CardContent>
                        <Typography variant="h6" color="text.secondary" gutterBottom>
                            Leyenda
                        </Typography>
                    </CardContent>
                </Card>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Card sx={{ mt:5 }}>
                            <CardContent>
                                <Typography sx={{ fontSize:14, height:"90px" }} color="text.secondary" gutterBottom>
                                    <b>PCE - Parcialmente Cumple las Expectativas:</b><br/>Logra algunas expectativas pero puede no ser de manera consistente.
                                </Typography>
                            </CardContent>
                        </Card>        
                    </Grid>
                    <Grid item xs={4}>
                        <Card sx={{  mt:5 }}>
                            <CardContent>
                                <Typography sx={{ fontSize:14, height:"90px" }} color="text.secondary" gutterBottom>
                                    <b>CE - Cumple las Expectativas:</b><br/> Logra las expectativas y ocasionalmente excede en el logro de los resultados, y demuestra comportamientos asociados al como lo hace.
                                </Typography>
                            </CardContent>
                        </Card>      
                    </Grid>
                    <Grid item xs={4}>
                        <Card sx={{  mt:5 }}>
                            <CardContent>
                                <Typography sx={{ fontSize:14, height:"90px" }} color="text.secondary" gutterBottom>
                                    <b>SE - Supera las Expectativas:</b><br/> De manera significativa y consistentemente excede las expectativas tanto en el logro de los resultados, como en el modelaje de comportamientos asociados al como lo hace.
                                </Typography>
                            </CardContent>
                        </Card> 
                    </Grid>
                </Grid>
                <Card sx={{ minWidth: 275, mb:5, mt:5 }}>
                    <CardContent>
                        <Typography variant="h6" color="text.secondary" gutterBottom>
                            Resumen de Respuestas
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TableContainer >
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                        <TableRow>
                                            <TableCell>Clasificación Global</TableCell>
                                            <TableCell align="right">SE</TableCell>
                                            <TableCell align="right">CE</TableCell>
                                            <TableCell align="right">PCE</TableCell>
                                            <TableCell align="right">Subtotal</TableCell>
                                        </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {   
                                            summary.map( item => {
                                                { total = item.Valor[5] }
                                                return (<TableRow>
                                                    <TableCell scope="row">{ item.titulo }</TableCell>
                                                    <TableCell align="right">{ item.Valor[1] }</TableCell>
                                                    <TableCell align="right">{ item.Valor[2] }</TableCell>
                                                    <TableCell align="right">{ item.Valor[3] }</TableCell>
                                                    <TableCell align="right">{ item.Valor[4] }</TableCell>
                                                </TableRow>)
                                            }) 
                                        }

                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                <TableCell colspan="4" scope="row" align="right">
                                                    <Typography variant="h6">Total</Typography>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Typography variant="h6">
                                                        { total }
                                                    </Typography>
                                                </TableCell>
                                            </TableRow> 
                                        </TableFooter>
                                    </Table> 
                                </TableContainer>  
                            </Grid>
                        </Grid>
                            
                    </CardContent>
                </Card>
                <Card sx={{ minWidth: 275, mb:5 }}>
                    <CardContent>
                        
                        <Typography variant="h6" sx={{ mt:5 }} color="text.secondary" gutterBottom>
                            Listado de Preguntas y Respuestas
                        </Typography>
                        {   data.length == 0 && !showError &&
                                <CircularProgress/>
                        } 
                        {   
                            data.length > 0 &&
                            data.map( item => {
                                {
                                    return typeof(item.subsecciones) == "object" &&
                                        item.subsecciones.map( subsection => {
                                            return (
                                            <Fragment>
                                                <Typography variant="h6" sx={ { mt:2 } } color="text.secondary" gutterBottom>
                                                    { subsection.Subseccion }
                                                </Typography>
                                                <TableContainer >
                                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>Pregunta</TableCell>
                                                                <TableCell align='center'>Respuesta</TableCell>
                                                                <TableCell>Evidencia Conductual</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {
                                                                subsection.Preguntas.map( question => {
                                                                    return (
                                                                        <Fragment>
                                                                        <TableRow>
                                                                            <TableCell sx={ { width:"50%" } }>
                                                                                { question.preguntas } 
                                                                            </TableCell>
                                                                            <TableCell sx={ { width:"10%" } } align='center'>
                                                                                {   question.resueltas.length > 0 && 
                                                                                     question.resueltas[0].Respuesta 
                                                                                } 
                                                                            </TableCell>
                                                                            <TableCell sx={ { width:"40%" } }>
                                                                                {  question.resueltas.length > 0 && question.resueltas[0].Observacion !== "S/N" &&
                                                                                    question.resueltas[0].Observacion  
                                                                                }
                                                                            </TableCell>
                                                                       
                                                                        </TableRow>    
                                                                        </Fragment>
                                                                    )
                                                                })
                                                                
                                                            }
                                                        </TableBody>    
                                                </Table>
                                                </TableContainer>
                                            </Fragment>
                                            )
                                        })
                                }
                            })
                        }
                    </CardContent>
                </Card>
                </div>
            </Container>            
        </Fragment>
    )
}

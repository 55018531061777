import React from 'react'

import { Grid,Paper,TextField,FormControl } from "@mui/material"

import { InputOptions } from "../forms/InputOptions";
import { InputTypeQuestion } from "../forms/InputTypeQuestion";
import { ButtonGroupSection } from "../menus/ButtonGroupSection";

export const Question = ( { form, setForm, question, sectionIndex, questionIndex } ) => {

    return (
        <>
            <Paper key={ question.id } sx={ { p:5, mb:5 }}>
                <Grid container spacing={1}>
                    <Grid 
                        container 
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                    >   
                        { /******* Menu Header Sections*******/}
                        <ButtonGroupSection 
                            form={form} 
                            setForm={setForm} 
                            question={ question }
                            sectionIndex={ sectionIndex } 
                            questionIndex={ questionIndex }
                        />
                    </Grid>  
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <TextField label="Pregunta Sin Título" value={ question.title } variant="outlined" />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        { /******* Input Type Questions*******/}
                        <InputTypeQuestion 
                            initValue={1}
                            form={ form } 
                            setForm={ setForm } 
                            question={ question } 
                            sectionIndex={ sectionIndex } 
                            questionIndex={ questionIndex }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField label="Descripción" autocomplete="off" value={ question.description } variant="outlined" />
                        </FormControl>
                    </Grid>
                    { /******* Input Options*******/}
                    
                    {  question.type == 1 &&
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    disabled
                                    label="Texto de repuesta corta"
                                    defaultValue="Texto de repuesta corta"
                                    variant="filled"
                                />
                            </FormControl>
                        </Grid>
                    }
                    
                    <InputOptions 
                        form={ form } 
                        setForm={ setForm } 
                        question={ question } 
                        sectionIndex={ sectionIndex } 
                        questionIndex={ questionIndex } 
                    />
                </Grid>
            </Paper> 
        </>
    )
}

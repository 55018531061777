import React, { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'

import { TableCell, TextField } from '@mui/material'

import { UserContext } from "../../contexts/UserContext";
import FormService from "../../services/FormService";
import { Fragment } from 'react';


export const QuestionOptions = ({ options, question, personal, periodo, indexSection, indexSubsection, data, setData, indexQuestion }) => {

    const userContext = useContext(UserContext);

    let { id: idForm, nivel, cedula, idpersonal } = useParams()
    let questionRow = data[indexSection].subsecciones[indexSubsection].Preguntas[indexQuestion]

    const [observacion, setObservacion] = useState("");
    const [disabledText, setDisabledText] = useState( ((questionRow.resueltas) && questionRow.resueltas.length > 0  || !idpersonal )? false : true);  //!questionRow.resueltas.length > 0
    
    const [answer, setAnswer] = useState({
        idformulario: idForm,
        idUsuario: userContext.id,
        idpregunta: questionRow.id,
        idpersonal: personal.idpersonal,
        idrespuesta: 0,
        valor: 0,
        periodo,
        observacion: (  (questionRow.resueltas) && questionRow.resueltas.length > 0)? questionRow.resueltas[0].Observacion : "S/N"
    })

    useEffect(() => {
      
        if( (questionRow.resueltas) && questionRow.resueltas.length > 0){
            if(questionRow.resueltas[0].Observacion !== "S/N"){
                setObservacion(questionRow.resueltas[0].Observacion)
            }
        }

    }, [])

    const handleChangeCheckbox = (question, option) => {
        
        let check = {}

        setAnswer({
            ...answer,
            idpregunta: question.id,
            idpersonal: personal.idpersonal,
            idrespuesta: option.id,
            valor: option.valor,
            periodo
        })

        if( (questionRow.resueltas) && questionRow.resueltas.length > 0){
            
            questionRow.resueltas[0].RespuestaID = option.id
            questionRow.resueltas[0].valor = option.valor
            questionRow.resueltas[0].Respuesta = option.titulo

            check = {
                idformulario: idForm,
                idUsuario: userContext.id,
                idpregunta: question.id,
                idpersonal: personal.idpersonal,
                idrespuesta: option.id,
                valor: option.valor,
                periodo,
                observacion: questionRow.resueltas[0].Observacion
            } 
            
            sendAnswer(check)

        }else{
            
            check = {
                id: question.id,
                preguntas: questionRow.preguntas,
                valor: option.valor,
                periodo,
                RespuestaID: option.id,
                Respuesta: option.titulo,
                Observacion: "S/N"
            }
            
            questionRow.resueltas.push(check)

            sendAnswer({
                idformulario: idForm,
                idUsuario: userContext.id,
                idpregunta: question.id,
                idpersonal: personal.idpersonal,
                idrespuesta: option.id,
                valor: option.valor,
                periodo
            })
        }

        setData(data)
        setDisabledText(false)
    };

    const handleChangeObservacion = (event) => {
        setObservacion(event.target.value)
    }

    const handleChangeTextField = (event) => {
       
        setAnswer({
            ...answer,
            observacion: event.target.value
        })

        if( (questionRow.resueltas) && questionRow.resueltas.length > 0){
            questionRow.resueltas[0].Observacion = event.target.value
            
            sendAnswer({
                idpregunta: question.id,
                idpersonal: personal.idpersonal,
                idrespuesta: questionRow.resueltas[0].RespuestaID,
                observacion: event.target.value,
                valor: questionRow.resueltas[0].valor,
                periodo
            })

        }else{
            
            let text = {
                id: question.id,
                preguntas: questionRow.preguntas,
                valor: "",
                RespuestaID: "",
                Respuesta: "",
                periodo,
                Observacion: event.target.value
            }

            questionRow.resueltas.push(text)
        }

        setData(data)
    }

    const sendAnswer = data => {
        FormService.addAnswer(data).then((res) => {
            console.log(res)
        }).catch( (error) => {
            alert("Conexión lenta, no se ha guardado su respuesta");
        })
    }

    return (
        <Fragment>
            {
                options.map( option => {
                    
                    return ( 
                        <Fragment>
                        { (question.resueltas) && question.resueltas.length > 0 &&
                            <TableCell>
                                <input name={question.id} checked={ question.resueltas[0].RespuestaID == option.id }  onChange={ (e) => {handleChangeCheckbox(question, option) }} className="radio" type="radio" value={option.valor} />
                            </TableCell>
                        }
                        
                        { (question.resueltas) && question.resueltas.length == 0 &&
                            <TableCell>
                                <input name={question.id} onChange={ (e) => {handleChangeCheckbox(question, option) }} className="radio" type="radio" value={option.valor} />
                            </TableCell>
                        }
                        {
                            (!question.resueltas) &&
                            <TableCell>
                                <input name={question.id} onChange={ (e) => {handleChangeCheckbox(question, option) }} className="radio" type="radio" value={option.valor} />
                            </TableCell>
                        } 
                        </Fragment>
                    )    
                        
                })
            }

            <TableCell sx={ {width: 400} }>
                <TextField
                    multiline
                    maxRows={1}
                    disabled={ disabledText }
                    value={ observacion }
                    onChange={handleChangeObservacion}
                    onBlur={handleChangeTextField}/>
            </TableCell>
        </Fragment>
    )
}

import React from 'react'

import { Container, Typography } from '@mui/material'

export const Footer = () => {

  const year = new Date().getFullYear()
    
  return (
    <>
        <Container maxWidth="md" sx={ { mt: 5, mb: 5} }>
            
            <Typography sx={ { fontSize: "12px", textAlign: "center"} }>
              Copyright&copy; { year } Grupo Serex, C.A.
            </Typography>
            <Typography sx={ { fontSize: "12px", textAlign: "center"} }>
              Gerencia de Tecnologías de la Información y Comunicación (TIC)
            </Typography>
            <Typography sx={ { fontSize: "12px", textAlign: "center" } }>
                Unidad de Desarrollo y Mantenimiento de Sistemas
            </Typography>
        </Container>
    </>
  )
}

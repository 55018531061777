import React, { useEffect, useState } from 'react'

import { useParams, useNavigate } from 'react-router-dom'

import FormService from "../../services/FormService";
import EmployeeService from "../../services/EmployeeService";

import { setFormatText } from "../../common/utils"

export const FormResponsePrint = () => {
    var total = 0
    let { id:idForm, personal: idPersonal } = useParams()
    const navigate = useNavigate()

    const [data, setData] = useState([]);
    const [summary, setSummary] = useState([]);
    const [showError, setShowError] = useState(false);

    const [personal, setPersonal] = useState({
        Apellido: "",
        Cargo: "",
        Cedula: "",
        Empresa: "",
        Gerencia: "",
        Nivel_Contribuyente: "",
        Nombre: "",
        Supervisor: "",
        idpersonal: 0,
        Fecha_ingreso: ""
    })

    useEffect(() => {
        getFormData(idForm, idPersonal)
        getFormAnswersSummary(idForm, idPersonal)
        getEmployee(idPersonal)
    }, []) 
    
    const getFormData = () => {
        FormService.getFormAnswersByPersonal(idForm,idPersonal).then((res) => {
            setData(res.data)
        }).catch( (error) => { 
            setShowError(true)
        });
    }

    const getFormAnswersSummary = (idForm, idPersonal) => {
        FormService.getFormAnswersSummary(idForm,idPersonal).then((res) => {
            setSummary(res.data)
        }).catch( (error) => { 
            setShowError(true)
        });
    }
    
    const getEmployee = (idPersonal) => {
        EmployeeService.getEmployee(idPersonal).then((res) => {
            setPersonal(res.data)
        }).catch( (error) => { 
            setShowError(true)
        });
    }
  
    return (
        <>
          
        </>
    )
}

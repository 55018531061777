import { React, useState, useContext } from 'react';

import logo from '../icon.png';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';

import { UserContext } from '../contexts/UserContext';
import { Link, useNavigate } from 'react-router-dom';

var pages = [{
  to: "/", 
  text: "FORMULARIOS"
}]

var settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

export const Header = () => {
  
  const userContext = useContext(UserContext);
  const navigate = useNavigate()

  const [anchorElUser, setAnchorElUser] = useState(null);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const [open, setOpen] = useState(false);
  const [logged, setLogged] = useState(false);
  
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleLogout = () => {
    localStorage.removeItem("auth")
    setLogged(true)
    navigate("auth/login")
    window.location.reload()
    setOpen(false)
  }

  return (
    <>
    {  !logged  && 
      <AppBar className='no-print' position="fixed">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            {/* <img src={logo} id="logo_full" alt="logo" width="200px"/> */}

            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
            >
              <img src={logo} alt="logo" height="40" width="40"/>
            </Typography>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
            >
              <img src={logo} alt="logo" height="40" width="40"/>
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {pages.map((page) => (
                <Link key={page.to} to={page.to}>
                <Button
                  key={page.text}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  {page.text}
                </Button>
                </Link>
              ))}
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title={userContext.nombre}>
                <IconButton sx={{ p: 0 }} onClick={ handleOpen }>
                  <Avatar alt={userContext.nombre} src="/static/images/avatar/2.jpg" />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting}>
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
          </Container>
      </AppBar>
    }
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
            <Button sx={ { ml: "33%" } } onClick={handleLogout}>Cerrar sesión</Button>
        </Box>
    </Modal>
    
    </>
  );
};

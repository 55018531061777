import React from 'react'

import { Grid,TextField,FormControl,Button,Tooltip,InputAdornment } from "@mui/material"

/*******Icons*******/
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from "@mui/icons-material/Delete";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';


export const InputOptions = ( { form, setForm, question, sectionIndex, questionIndex } ) => {

    const typeOption = form.sections[sectionIndex].questions[questionIndex].type;

    const handleAddOption = () => {

        var options = question.options;

        var questions = question.options.push({
            text: `Opcion :${ (options.length+1) }`,
            value: ""
        })

        setForm({
            ...form,
            questions
        })
    }

    const handleRemoveOption = ( index ) => {
        
        var questions = form.sections[sectionIndex].questions;
        questions[questionIndex].options.splice(index, 1);

        setForm({
            ...form,
            questions
        });

    }

    return (
        <>
          {
            question.options.map( (option, index) => {
                return (
                    <>
                        { typeOption !== 1 &&
                        <>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <TextField 
                                        label={ `Opción número: ${index+1}` } 
                                        value={ option.text } 
                                        autocomplete="off"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    {
                                                        typeOption == 2 &&
                                                            <RadioButtonCheckedIcon/>
                                                    }
                                                    {
                                                        typeOption == 3 &&
                                                            <CheckBoxIcon />
                                                    }
                                                    {
                                                        typeOption == 4 &&
                                                            <ArrowDropDownCircleIcon />
                                                    }
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="outlined" />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl >
                                    <TextField
                                        label="Puntos"
                                        type="number"
                                        autocomplete="off"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                    />
                                </FormControl>    
                            </Grid>
                            <Grid container item justifyContent="flex-end" xs={3} sx={{ mt:1 }}>
                            { index == (question.options.length-1) && 
                                <Tooltip title="Agregar Opción">
                                    <Button color="info" onClick={ handleAddOption }>
                                        <AddIcon></AddIcon>
                                    </Button>
                                </Tooltip>
                            }
                            <Tooltip title="Eliminar Opción">
                                <Button color="error" onClick={ () => handleRemoveOption(index) }>
                                    <DeleteIcon></DeleteIcon>
                                </Button>
                            </Tooltip>    
                        </Grid>
                        </>
                        }
                    </>
                )
            })
        }     
        
        </>
    )
}

import React, { useState } from 'react'

import logo from '../../../logo_full.png';

import { useForm } from "react-hook-form";

import { Box, Button, Card, CardContent, CircularProgress, Container, 
FormControl, Snackbar, TextField, Typography } from '@mui/material'

import AuthService from '../../../services/AuthService';
import { useNavigate } from 'react-router-dom';

export const Login = () => {

    const navigate = useNavigate()
    const [open, setOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const { register, formState: { errors }, handleSubmit, reset } = useForm();

    const handleLogin = data => {
        
        setIsLoading(true)

        AuthService.login(data).then((res) => {
            if(res.status == 200){
                
                reset()
                let auth = JSON.stringify(res.data.Sessions)
                localStorage.setItem("auth",auth)
                navigate("/")
                window.location.reload()
            }

        }).catch( (error) => {
            if(/500/.test(error)){
                setErrorMessage("Credenciales inválidas")
            }
            
            if(/Network/.test(error)){
                setErrorMessage("Error de conexión a internet")
            }
            
            setOpen(true)
            setIsLoading(false)
        } );
    }

    return (
        <>
            <Container id="container" maxWidth="xs"  sx={ { mt:20 } }>    
                <Card sx={{ mb:5 }}>
                    <CardContent>
                        <img src={logo} id="logo_full" alt="logo" width="200px"/>

                        {   isLoading && 
                            <Box sx={{ display: 'flex', ml:20 }}>
                                <CircularProgress/>
                            </Box>
                        }
                        <Snackbar
                            open={open}
                            autoHideDuration={3000}
                            message={errorMessage}
                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                            key="topcenter"> 
                        </Snackbar>
                        <form onSubmit={handleSubmit(handleLogin)}>

                        <FormControl sx={{ mt:2, mb: 1, width: '100%' }} variant="standard">
                            <TextField 
                                name="username" autoComplete='off' 
                                label="Usuario/Correo" variant="outlined"
                                {...register("username", { required: true } )}
                                error={ errors?.username?.type =="required" } 
                                helperText={ errors?.username?.type =="required"? "Ingrese un usuario/correo electrónico" : ""}/>
                        </FormControl>
                        <FormControl sx={{ width: '100%' }} variant="standard">
                            <TextField 
                                name="password" type="password" 
                                autoComplete='current-password'
                                label="Contraseña" variant="outlined"
                                {...register("password", { required: true } )}
                                error={ errors?.password?.type =="required" } 
                                helperText={ errors?.password?.type =="required"? "Ingrese una contraseña" : ""}/>
                        </FormControl>
                        <Button sx={{ mt:1 }} type="submit" variant="contained">Entrar</Button>
                        </form>
                    </CardContent>
                </Card>
            </Container>    
        </>
    )
}

export const setFormatText = (text, format)=> {

    if(text !== ""){
        switch (format) {
            case "cedula":
                text = text.replace(/\D/g, "")
                        .replace(/([0-9])([0-9]{3})$/, '$1.$2')
                        .replace(/\B(?=(\d{3})+(?!\d)\.?)/g,".")
            break;
            case "fecha":
                let split = text.split("-")
                text = `${split[2]}-${split[1]}-${split[0]}`
            break;
        }
    }

    return text

}
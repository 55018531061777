
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import Modal from '@mui/material/Modal';
import AddIcon from '@mui/icons-material/Add';
import Snackbar from '@mui/material/Snackbar';
import { 
  Button, Card, CardActions, CardContent, InputLabel, Select, MenuItem, CircularProgress,
  Container, Fab, Grid, Typography, Box, TextField, FormControl, FormHelperText, Alert, Tooltip
} from "@mui/material"

import BorderColorIcon from '@mui/icons-material/BorderColor';
import ViewListIcon from '@mui/icons-material/ViewList';
import ReplyIcon from '@mui/icons-material/Reply';

import FormService from "../../services/FormService";
import { Link } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";

export const Home = () => { 

    const userContext = useContext(UserContext);

    const { register, formState: { errors }, handleSubmit, setValue, setError, reset } = useForm();

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const [open, setOpen] = useState(false);
    const [formList, setFormList] = useState([])
    const [showMessage, setShowMessage] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        if(userContext.rol && userContext.id){
            getAllForms(userContext.id, userContext.rol)
        }

    }, [userContext.rol, userContext.id])

    const getAllForms = (user, role) => {

        FormService.getForms(user,role).then((res) => {
            setFormsList(res.data)
        });
    }
    
    const setFormsList = (items) => {
        setFormList(items)
    } 

    const handleAdd = (data) => {

        let form = {...data, idtipoformulario: data.Tipo, idUsuario: userContext.id}
        delete form.Tipo 
        
        FormService.addForm(form).then((res) => {
            if(res.status == 201){
                setOpen(false)
                setShowMessage(true)
                getAllForms(userContext.id, userContext.rol)
                reset()
            }
        }); 
    }

    return (
        <>
            <Snackbar
                open={showMessage}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                key="topcenter"
            > 
                <Alert severity="success" sx={{ width: '100%' }}>
                    Datos guardados!!
                </Alert>
            </Snackbar>
            {  userContext.rol == 232 &&
                <Fab id="btnAddForm" 
                    onClick={handleOpen} 
                    size="large" 
                    title="Nuevo Formulario" 
                    color="secondary" 
                    aria-label="add"
                    sx={{
                        position: 'absolute',
                        bottom: 16,
                        right: 16,
                    }}>
                    <AddIcon/>
                </Fab>
            }

            <Container id="container" maxWidth="lg"  sx={ {  mt:15, mb:15 } }>
                
                <Grid container spacing={2}>
                {
                    formList.length == 0 &&
                        <CircularProgress/>
                }    
                {  
                    formList.map( form => {  
                        return (
                            <Grid item xs={12}>
                                <Card variant="outlined" key={ form.idform }>
                                    <CardContent>
                                        <Typography variant="h5" component="div">
                                            { form.Titulo }
                                        </Typography>
                                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                            Creado {form.Fecha}
                                        </Typography>
                                        <Typography variant="body2">
                                            { form.Descripcion }
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        {  userContext.rol == 232 &&
                                            <Link to={`./forms/${form.idform}`} >
                                                <Button size="small" variant="outlined">
                                                    <Tooltip title="Editar Formulario">
                                                        <BorderColorIcon/>
                                                    </Tooltip>
                                                </Button>
                                            </Link>
                                        }
                                        <Link to={`./forms/response/${form.idform}/${form.Nivel_contribuyente}/${userContext.Cedula}`}>
                                            <Button size="small" variant="contained">
                                                <Tooltip title="Responder Formulario">
                                                    <ReplyIcon/>
                                                </Tooltip>
                                            </Button>
                                        </Link>
                                        <Link to={`forms/responses/${form.idform}`} >
                                            <Button size="small" variant="contained">
                                                <Tooltip title="Ver listado de respuestas">
                                                    <ViewListIcon/>
                                                </Tooltip>    
                                            </Button>
                                        </Link>
                                    </CardActions>
                                </Card>        
                            </Grid>
                        )
                    })
                }
                </Grid>
            </Container>
            
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <form onSubmit={handleSubmit(handleAdd)}>
                    <Typography variant="h6" sx={{ mb:3 }}>Nuevo Formulario</Typography>
    
                    <FormControl fullWidth sx={{ '& .MuiTextField-root': { m: 1 }}}>
                        <InputLabel>Tipo de Formulario</InputLabel>
                        <Select 
                            sx={{ m:1 }}
                            name="Tipo"
                            {...register("Tipo", { required: true } )}
                            label="Tipo de Formulario"
                            error={ errors?.Titulo?.type =="required" }
                        >
                            <MenuItem value={1}>Evaluación</MenuItem>
                        </Select>
                        <FormHelperText error={ errors?.Titulo?.type =="required" }>
                            { errors?.Subtitulo?.type =="required"? "Este campo es requerido" : "" }
                        </FormHelperText>
                        
                        <TextField name="Titulo" 
                            label="Título" {...register("Titulo", { required: true } )} 
                            autoComplete="off"  
                            variant="outlined"
                            error={ errors?.Titulo?.type =="required" } 
                            helperText={ errors?.Titulo?.type =="required"? "Este campo es requerido" : ""}/>

                        <TextField name="Subtitulo" 
                            label="Subtítulo" {...register("Subtitulo", { required: true } )} 
                            autoComplete="off" 
                            variant="outlined"
                            error={ errors?.Subtitulo?.type =="required" } 
                            helperText={ errors?.Subtitulo?.type =="required"? "Este campo es requerido" : ""}/>
                        <TextField name="Descripcion" 
                            label="Descripción" {...register("Descripcion", { required: true } )} 
                            autoComplete="off" 
                            variant="outlined"
                            error={ errors?.Descripcion?.type =="required" } 
                            helperText={ errors?.Descripcion?.type =="required"? "Este campo es requerido" : ""}/>
                        <Button type="submit">Guardar</Button>
                    </FormControl>    
                    </form>
                </Box>
            </Modal>
        </>
    )
}
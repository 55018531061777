import axios from 'axios';
import { BASE_URL_API } from "../common/config";

class EmployeeService {
    
    async getEmployees(nivel, cedula, idPersonal = "0", idPeriodo = "0") {
        return await axios.get(`${BASE_URL_API}/personal/personal/${nivel}/${cedula}/${idPeriodo}/${idPersonal}`)
    }
    
    async getEmployee(idPersonal) {
        return await axios.get(`${BASE_URL_API}/personal/personal_by_id/${idPersonal}`)
    }
}

export default new EmployeeService()
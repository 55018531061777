import { Fragment, React, useContext, useEffect, useState } from 'react';

import { Link, useParams } from 'react-router-dom';
import DataTable from 'react-data-table-component';

import PreviewIcon from '@mui/icons-material/Preview';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import { Card, CardContent, Container, Paper, Table, TableBody, TableCell, TableContainer, 
TableHead, TableRow, Typography, Tooltip, CircularProgress, Select, MenuItem, FormControl, InputLabel, TextField, Button, IconButton } 
from '@mui/material'
import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined';

import 'react-data-table-component-extensions/dist/index.css'

import FormService from "../../services/FormService";
import { UserContext } from '../../contexts/UserContext';

import gerencias from '../../data/gerencias';
import { useForm } from 'react-hook-form';
import { BASE_URL_API } from '../../common/config';


export const FormResponses = () => {

    let { id:idForm } = useParams()
    
    const userContext = useContext(UserContext);

    const [ data, setData] = useState([])
    const [ copies, setCopies] = useState([])
    const [ idGerencia, setIdGerencia] = useState('');
    const [ idPeriodo, setIdPeriodo] = useState('');

    const [ periodos, setPeriodos] = useState([]);

    const { register, handleSubmit, reset } = useForm();
    const [ isLoading, setIsLoading] = useState(true)
   
    const columns = [
        {
            name: 'Encargado',
            selector: row => row.Supervisor,
            sortable: true
        },
        {
            name: 'Gerencia',
            selector: row => row.Gerencia,
            sortable: true
        },
        {
            name: 'Cargo',
            selector: row => row.Cargo,
            sortable: true
        },
        {
            name: 'Colaborador',
            selector: row => row.Personal,
            sortable: true
        },
        {
            name: 'Periodo',
            selector: row => row.Periodo_Descripcion,
            sortable: true
        },
        {
            name: 'Puntuación',
            selector: row => row.valor,
            sortable: true
        },
        {
            name: 'Ver',
            cell: row => (
                <Fragment>
                    { row.Periodo_status == 1 &&
                        <Tooltip title="Editar respuestas">
                            <Link to={`/forms/response/${row.idformulario}/${row.Nivel_contribuyente}/${userContext.Cedula}/${row.idpersonal}/${row.Periodo}`}>
                                <EditIcon/>
                            </Link>
                        </Tooltip>
                    }
                    <Tooltip title="Ver detalles">
                        <Link to={`/forms/responses/details/${row.idformulario}/${row.idpersonal}/${row.Periodo}`}>
                            <PreviewIcon/>
                        </Link>
                    </Tooltip>
                </Fragment>

            )
        }
    ];

    useEffect(() => {

        getFormPeriodos();
        
        if(idForm && userContext.id && userContext.rol && userContext.Cedula){
            getFormAnswers(idForm, userContext.id, userContext.rol)
        }
    }, [userContext.id, userContext.rol]);

    const getFormAnswers = (idForm,user,role) => {
        
        FormService.getFormAnswers(idForm, user, role).then((res) => {
            setData(res.data)
            setCopies(res.data)
            setIsLoading(false)
        }).catch( (error) => {
            setIsLoading(false)
        });
    }

    const getFormPeriodos = () => {
        FormService.getFormPeriodos().then( (response) => {
            setPeriodos(response.data);
        }); 
    }

    const handleFilter = (data) => {

        var filtered = [];
        const periodo = periodos.filter( (item) => item.id == data.Periodo );
        var periodoText = (periodo.length > 0)? periodo[0].descripcion : "";

        if(useContext.rol == 1){
            filtered = copies.filter(
                copy => copy.Supervisor.toLowerCase().includes(data.Supervisor.toLowerCase()) &&
                copy.Personal.toLowerCase().includes(data.Colaborador.toLowerCase()) && 
                copy.Gerencia.includes(data.Gerencia) &&
                copy.Periodo_Descripcion.includes(periodoText)
            );
        }else{
            filtered = copies.filter(
                copy => copy.Supervisor.toLowerCase().includes(data.Supervisor.toLowerCase()) &&
                copy.Personal.toLowerCase().includes(data.Colaborador.toLowerCase()) && 
                copy.Periodo_Descripcion.includes(periodoText)
            );
        }
       
        setData(filtered);
    }

    const handleClearForm = () => {

        reset({
            Supervisor: "",
            Colaborador: "",
            Periodo:""
        })

        setIdGerencia("")
        setIdPeriodo("")

        handleFilter({
            Supervisor: "",
            Colaborador: "",
            Gerencia:"",
            Periodo:""
        })
    }

    const handleChangeGerencia = (event) => {
        setIdGerencia(event.target.value)
    };
    
    const handleChangePeriodo = (event) => {
        setIdPeriodo(event.target.value)
    };

    const handleClickExcel = () => {
        var periodo = idPeriodo? idPeriodo : 0;
        var url = `${BASE_URL_API}/formulario/exportExcell/${idForm}/${periodo}`;
        window.open(url,"_blank");
    }

    return (
        <Fragment>
            <Container id="container" maxWidth="lg"  sx={ { mt:15 } }>    
                <Card sx={{ minWidth: 275, mb:5 }}>
                    <CardContent>
                        <Typography variant="h6" color="text.secondary" gutterBottom>
                            Listado de Respuestas
                        </Typography>
                        
                            <Fragment>
                                <form onSubmit={handleSubmit(handleFilter)}>
                                    <FormControl sx={{ m: 1, width: '30ch' }}>
                                        <TextField name='Supervisor' autoComplete='off' label="Encargado" size="small" {...register("Supervisor")} variant="outlined"/>
                                    </FormControl>
                                    <FormControl sx={{ m: 1, width: '30ch' }}>
                                        <TextField name='Colaborador' autoComplete='off' label="Colaborador" size="small" {...register("Colaborador")} variant="outlined"/>
                                    </FormControl>
                                    <FormControl sx={{ m: 1, width: '30ch' }} size="small">
                                        <InputLabel>Seleccione un período</InputLabel>
                                        <Select name="Periodo" value={idPeriodo}  label="Período" {...register("Periodo")} onChange={handleChangePeriodo}>  
                                            <MenuItem value=""><em>Seleccione</em></MenuItem>
                                            {
                                                periodos.map( periodo => {
                                                    return <MenuItem value={periodo.id}> {periodo.descripcion}</MenuItem>
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                    { userContext.rol == 1 &&
                                        <FormControl sx={{ m: 1, width: '30ch' }} size="small">
                                            <InputLabel>Seleccione una Gerencia</InputLabel>
                                            <Select name="Gerencia" value={idGerencia} label="Gerencia"  {...register("Gerencia")} onChange={handleChangeGerencia}>   
                                                <MenuItem value=""><em>Seleccione</em></MenuItem>
                                                {
                                                    gerencias.map( gerencia => {
                                                        return <MenuItem value={gerencia}> {gerencia}</MenuItem>
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                    }
                                    <Button id="btnSearch" variant="contained" sx={{ mt:1, ml:1 }} type="submit">
                                        <ManageSearchOutlinedIcon/> Buscar
                                    </Button>
                                    <Button variant="outlined" sx={{ mt:1, ml:1 }} onClick={ handleClearForm }>
                                        <ClearIcon/> Limpiar
                                    </Button>
                                </form>
                                { userContext.rol == 1 &&
                                    <Button id="btn-excel" onClick={ handleClickExcel } variant="contained" color="success" sx={{ mt:2, mr:4 }}>
                                        <FileDownloadIcon/> Excel
                                    </Button> }
                            </Fragment>
                        
                        <DataTable
                            columns={columns}
                            data={ data }
                            pagination={ true }
                            highlightOnHover={ true }
                            paginationPerPage={ 30 }
                            paginationRowsPerPageOptions={[30,60,90,120,150]}
                            paginationComponentOptions={ {
                                rowsPerPageText: 'Filas por página:', 
                                rangeSeparatorText: 'de', 
                                noRowsPerPage: false, 
                                selectAllRowsItem: true, 
                                selectAllRowsItemText: 'Todos',
                            }}
                            noDataComponent="No hay datos para mostrar"/>
                    </CardContent>    
                </Card>
            </Container>   
        </Fragment>
    )
}

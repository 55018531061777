import React, { useState, useEffect } from 'react';

import {
    HashRouter as Router,
    Route,
    Routes
  } from "react-router-dom";

import { Header } from '../components/Header';
import { Footer } from '../components/Footer';

import { Home } from '../components/pages/Home';
import { Forms } from '../components/pages/Forms';
import { Login } from '../components/pages/auth/Login';
import { FormClient } from '../components/pages/FormClient';
import { FormResponses } from '../components/pages/FormResponses';
import { FormResponseDetails } from '../components/pages/FormResponseDetails';

import { UserContext } from '../contexts/UserContext';
import { FormResponsePrint } from '../components/pages/FormResponsePrint';

export const AppRouter = () => {

    const [auth, setAuth] = useState({})
    const [isAuthenticated, setIsAuthenticated] = useState(false)

    useEffect(() => {

        let item = localStorage.getItem("auth")
        item = JSON.parse(item)
    
        if(item){
          setIsAuthenticated(true)
          setAuth(item)
        }else{
          if(!/login/.test(window.location.pathname)){
            window.location.href = window.location.origin +"/#/auth/login"
          }
        }
    
    }, []);

    return (
        <Router key="router">
            <UserContext.Provider value={auth}>
                { isAuthenticated &&
                <   Header />
                }
                <Routes key="routes">
                    <Route  exact path="/" element={<Home/>} />           
                    <Route  exact path="/auth/login" element={<Login/>} />           
                    <Route  exact path="/forms/:id" element={<Forms/>}/>           
                    <Route  exact path="/forms/response/:id/:nivel/:cedula" element={<FormClient/>}/>           
                    <Route  exact path="/forms/response/:id/:nivel/:cedula/:personal/:periodo" element={<FormClient/>}/>           
                    <Route  exact path="/forms/response/details/:id/:user" element={<FormClient/>}/>           
                    <Route  exact path="/forms/responses/:id" element={<FormResponses/>}/>           
                    <Route  exact path="/forms/responses/details/:id/:personal/:periodo" element={<FormResponseDetails/>}/>           
                    <Route  exact path="/forms/responses/details/:id/:personal" element={<FormResponseDetails/>}/> 
                    <Route  exact path="/forms/responses/print/:id/:personal" element={<FormResponsePrint/>}/> 
                </Routes>
            </UserContext.Provider>
            <Footer/>
        </Router>        
    )
}
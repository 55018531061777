export default [
"Gerencia",
"Administraci?n",
"Administracion",
"Auditoria",
"Capital Humano",
"Comercializacion",
"Compras",
"Contabilidad",
"Costos",
"Finanzas",
"Gesti?n",
"Gestion",
"Impuestos",
"Mantenimiento Alm",
"Mantenimiento Pto Cab",
"Mantenimiento TC",
"Mantenimiento VP",
"Negocio",
"Operaciones",
"Operaciones Aba",
"Operaciones Aba SG",
"Operaciones Alm",
"Operaciones Cacao",
"Operaciones Engorde",
"Operaciones Pto Cab",
"Operaciones Pto Mcbo",
"Operaciones Redes",
"Operaciones Reproductora",
"Operaciones Segramar",
"Operaciones TC",
"Operaciones TM",
"Operaciones VP",
"Operacions Aba",
"Operacions TC",
"Planta Beneficio",
"Proyectos",
"Reproductora",
"Tecnologia",
"Tesorer?a",
"Ventas"
]